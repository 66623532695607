export default {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^\+?[\d]{1,3}[\d\s-]{6,20}$/,
  url: /^https?:\/\/.+$/,
  slug: /^[a-z0-9_-]+(?:-[a-z0-9_-]+)*$/,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
  managerTag: /^[a-zA-Z]+$/,
  promoCode: /^[A-NP-Z1-9]{1,16}$/,
  nuxtLinkUrl: /^[a-z0-9/_-]+(?:-[a-z0-9/_-]+)*$/,
  landing_name: /^[a-z0-9_-]+$/,
  landing_name_exclude:
    /^\b(dev)\b|\b(test)\b|\b(addwine)\b|\b(localhost)\b|\b(new)\b|\b(undefined)\b$/,
  linkStartSlash: /^\/(.*)/,
  landingUrl: /htt(p|ps):\/\/|\//g
}
