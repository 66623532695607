<template>
  <div :class="$style.uploader" :style="`max-width: ${maxWidth}rem`">
    <el-upload
      multiple
      action=""
      :auto-upload="false"
      :show-file-list="false"
      :on-change="upload"
      :disabled="files.length >= limit"
    >
      <el-button size="small" type="primary">Загрузить</el-button>
    </el-upload>
    <div>
      <div :class="$style.file" v-for="(f, i) in files" :key="i">
        <img
          v-if="isShowImage"
          :src="$configData.s3_link + f.original"
          alt=""
        />
        <el-input
          v-if="!withoutCaption"
          :disabled="isCaptionDisabled"
          :value="f.caption"
          :class="$style.input"
          @input=";(f.caption = $event), $emit('update:files', files)"
        >
        </el-input>
        <Icon name="trash" :class="$style.icon" @click="remove(f)" />
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import notifications from '@/mixins/notifications'

export default {
  components: {
    Icon,
  },
  mixins: [notifications],
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    files: {
      type: Array,
      default: () => [],
    },
    withoutCaption: {
      type: Boolean,
      default: false,
    },
    isContentCore: {
      type: Boolean,
      default: false,
    },
    withoutDeleteRequest: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 16,
    },
    isShowImage: {
      type: Boolean,
      default: true,
    },
    isCaptionDisabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async upload(file) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.StorageServiceCore.FilesActions.upload(file.raw)
      if (error) {
        loading.close()
        return
      }

      if (this.files.length < this.limit) {
        this.files.push({
          original: value,
          caption: file.raw.name,
          order_field: this.files.length,
          isActive: true,
        })
        this.$emit('upload', this.files)
      } else {
        this.showNotification(
          `Файл ${file.name} не может быть загружен из-за превышения лимита(${this.limit}) загрузки`,
          'error',
        )
      }

      loading.close()
    },
    async remove(image) {
      const isConfirm = confirm('Вы точно хотить удалить файл?')

      if (!isConfirm) return

      if (!image.original) return

      const replacedTemp = image.original?.replace('v2/', '')

      const { error } = await delivery.StorageServiceCore.FilesActions.delete(
        replacedTemp,
      )

      if (error) return

      const index = this.files.findIndex((i) => i.original === image.original)
      this.files.splice(index, 1)
      this.files.forEach((item, index) => {
        item.order_field = index
      })
      this.$emit('upload', this.files)
    },
  },
}
</script>

<style lang="scss" module>
.uploader {
  display: flex;
  justify-content: space-between;
  .file {
    display: flex;
    align-items: center;
    img {
      width: 7rem;
      height: 7rem;
    }
    .input {
      width: 18.75rem;
      margin: 0 1rem;
    }
  }
  .icon {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}
</style>
